import React, { useEffect, useLayoutEffect } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";
import orderIcon from "../login/order.png";
import voucherIcon from "../login/voucher.png";
import sizeIcon from "../login/size.png";
import colorIcon from "../login/color.png";
import moment from "moment/moment";
import MainHeading from "../includes/MainHeading";
import Skeleton from "react-loading-skeleton";
import formatThousands from "format-thousands";
import Swal from "sweetalert2";
import GreetingsMessage from "../includes/GreetingsMessage";

export default function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [tableLoader, settableLoader] = useState(false);
  const [counters, setCounters] = useState([]);
  const [tableData, settableData] = useState([]);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id, adminName } = state;
  const navigate = useNavigate();

  const accountantList = async () => {
    settableLoader(true);
    try {
      const responce = await AdminListService.recentRewards();

      const res = responce.data.response;
      const results = [];
      res.map((value) => {
        return results.push({
          sale_id: value.sale_id,
          store: value.store,
          sale_name: value.sale_name,
          spiff_amount: value.spiff_amount,
          regDate: value.created_at,
          id: value.id,
        });
      });

      settableData([...results]);

      settableLoader(false);
    } catch (err) {
      settableLoader(false);
      settableData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      const counters = async (values) => {
        setLoading(true);
        try {
          const response = await AdminListService.getCounts(values);

          setCounters(response.data);
          setLoading(false);
        } catch (err) {
          setLoading(false);

          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnver: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      };
      counters();

      accountantList();
    } else {
      navigate("/admin/login", { replace: true });
    }

    // eslint-disable-next-line
  }, []);
  const archiveOrder = async (order_id) => {
    setLoading(true);
    try {
      const response = await AdminListService.orderArchive(admin_id, order_id);

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      setTimeout(() => {
        accountantList();
      }, 1000);
    } catch (err) {
      setLoading(false);

      if (
        err?.response?.data?.error &&
        typeof err?.response?.data?.error === "string"
      ) {
        toast.error(err?.response?.data?.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const delOrder = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletSingleOrders(
            delId,
            admin_id
          );

          accountantList();

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };
  const Table = ({ data }) => {
    return (
      <div className="table-responsive">
        <table className="text-nowrap mb-0 table">
          <thead className="table-light">
            <tr>
              <th scope="col">No</th>
              <th scope="col">Sales ID</th>
              <th scope="col">Store</th>
              <th scope="col">Sales Person Name</th>
              <th scope="col">Reward Amount</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {data?.length ? (
              data?.map((el, index) => (
                <tr key={index}>
                  <td className="align-middle">{index + 1} </td>
                  <td className="align-middle">
                    {el.sale_id ? el.sale_id : "N/A"}
                  </td>
                  <td className="align-middle">
                    {el.store ? el.store : "N/A"}
                  </td>
                  <td className="align-middle">
                    {el.sale_name ? el.sale_name : "N/A"}
                  </td>
                  <td className="align-middle">
                    $ {el.spiff_amount ? el.spiff_amount : "N/A"}
                  </td>
                  <td className="align-middle">
                    {moment(el.regDate).format("MM-DD-YYYY")}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <Sidebar />
          <div className="content-glass">
            <GreetingsMessage admin={adminName} />
            <MainHeading heading="Dashboard" />
          </div>

          <div className="row">
            <div className="mt-4 col-xl-3 col-lg-6 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="card-body bgColor">
                  <div className="card-glass">
                    <div className="white-bg">
                      {loading ? (
                        <Skeleton count={6} height="18px" />
                      ) : (
                        <>
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                              <h4 className="mb-0">Today Rewards</h4>
                            </div>
                            <div className="icon-shape">
                              <img
                                src={orderIcon}
                                alt="order-icon"
                                style={{ width: "50px", height: "50px" }}
                              />
                            </div>
                          </div>
                          <div>
                            <h1 className="fw-bold">
                              {formatThousands(
                                counters?.today_reward
                                  ? counters?.today_reward
                                  : 0,
                                { separator: "," }
                              )}
                            </h1>
                            {/* <p className="mb-0">
                            <span>
                              {" "}
                              {formatThousands(
                                counters?.archiveOrder
                                  ? counters?.archiveOrder
                                  : 0,
                                { separator: "," }
                              )}
                            </span>{" "}
                            Archive Orders
                          </p> */}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 col-xl-3 col-lg-6 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="card-body bgColor">
                  <div className="card-glass">
                    {loading ? (
                      <Skeleton count={6} height="18px" />
                    ) : (
                      <>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <h4 className="mb-0">RSA's</h4>
                          </div>
                          <div className="icon-shape ">
                            <img
                              src={colorIcon}
                              alt="color-icon"
                              style={{ width: "50px", height: "50px" }}
                            />
                          </div>
                        </div>
                        <div>
                          <h1 className="fw-bold">
                            {" "}
                            {formatThousands(
                              counters?.rsa ? counters?.rsa : 0,
                              {
                                separator: ",",
                              }
                            )}
                          </h1>
                          {/* <p className="mb-0">
                          <span>
                            {formatThousands(
                              counters?.activeColor ? counters?.activeColor : 0,
                              { separator: "," }
                            )}
                          </span>{" "}
                          Active Colors
                        </p> */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 col-xl-3 col-lg-6 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="card-body bgColor">
                  <div className="card-glass">
                    {loading ? (
                      <Skeleton count={6} height="18px" />
                    ) : (
                      <>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <h4 className="mb-0">Rewards</h4>
                          </div>
                          <div className="icon-shape ">
                            <img
                              src={sizeIcon}
                              alt="size-icon"
                              style={{ width: "50px", height: "50px" }}
                            />
                          </div>
                        </div>
                        <div>
                          <h1 className="fw-bold">
                            {formatThousands(
                              counters?.reward ? counters?.reward : 0,
                              { separator: "," }
                            )}
                          </h1>
                          {/* <p className="mb-0">
                          <span>
                            {formatThousands(
                              counters?.activeSize ? counters?.activeSize : 0,
                              { separator: "," }
                            )}
                          </span>{" "}
                          Active Sizes
                        </p> */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 col-xl-3 col-lg-6 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="card-body bgColor">
                  <div className="card-glass">
                    {loading ? (
                      <Skeleton count={6} height="18px" />
                    ) : (
                      <>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <h4 className="mb-0">Reports</h4>
                          </div>
                          <div className="icon-shape ">
                            <img
                              src={voucherIcon}
                              alt="voucher-icon"
                              style={{ width: "50px", height: "50px" }}
                            />
                          </div>
                        </div>
                        <div>
                          <h1 className="fw-bold">
                            {formatThousands(
                              counters?.report ? counters?.report : 0,
                              { separator: "," }
                            )}
                          </h1>
                          {/* <p className="mb-0">
                          <span>
                            {" "}
                            {formatThousands(
                              counters?.usedVoucher ? counters?.usedVoucher : 0,
                              { separator: "," }
                            )}
                          </span>{" "}
                          Used Vouchers
                        </p> */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Latest Rewards</h4>
                </div>

                {tableLoader ? (
                  <div className="card-body">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <Table data={tableData} />
                )}

                <div className="bg-white text-center card-footer">
                  <Link className="link-primary" to="/admin/manage-reward">
                    View All Rewards
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
