import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
export default function NotFound() {
  const locat = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (locat.pathname.indexOf("admin") > -1) {
      navigate("/admin/login");
    } else {
      navigate("/");
    }
  }, [locat.pathname]);
  return (
    <div className="showPage"></div>

  );
}