import React, { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const Eye = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div style={{ marginTop: "150px" }}>
      <label htmlFor="password">Password:</label>
      <input
        type={showPassword ? "text" : "password"}
        id="password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {password.length >= 1 && // Adjust the length threshold as needed
        // Render eye icon only when the length of the password reaches the threshold
        (showPassword ? (
          <VisibilityOffIcon
            onClick={handleTogglePassword}
            className="password-icon"
          />
        ) : (
          <VisibilityIcon
            onClick={handleTogglePassword}
            className="password-icon"
          />
        ))}
    </div>
  );
};

export default Eye;
