import React, { useEffect } from "react";
import $ from "jquery";
export default function ToTop() {
  const backToTop = () => {
    return (
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        600
      ),
      !1
    );
  };

  useEffect(() => {
    $(window).on("scroll", function () {
      $(this).scrollTop() > 300
        ? $(".back-to-top").addClass("showbtn")
        : $(".back-to-top").removeClass("showbtn");
    });
  }, []);
  return (
    <>
      <div className="overlay nav-toggle-icon"></div>

      <div className="back-to-top" onClick={backToTop}>
        <i className="bi bi-arrow-up top-arrow"></i>
      </div>
    </>
  );
}
