import React, { useState, useEffect } from "react";

const GreetingsUserMessage = (props) => {
  const [greet, setGreet] = useState("");

  useEffect(() => {
    const myDate = new Date();
    const hrs = myDate.getHours();

    let greeting;

    if (hrs < 12) greeting = "Good Morning";
    else if (hrs >= 12 && hrs <= 17) greeting = "Good Afternoon";
    else if (hrs >= 17 && hrs <= 24) greeting = "Good Evening";

    setGreet(greeting);
  }, []); // Empty dependency array means the effect runs once on mount

  return (
    <div id="greetings">
      <div className="heading-user-sectiom greetings-message user">
        {greet},{" "}
        <strong className="user-name">
          {props.admin}
        </strong>
      </div>
    </div>
  );
};

export default GreetingsUserMessage;
