import React from "react";

export default function MainHeading({ heading }) {
  return (
    <div className="heading-sectiom">
      {heading}
      <a className="go-Btn" href="/" target="blank">
        Go to Live Site
      </a>
      <div className="clearfix"></div>
    </div>
  );
}
