import React from "react";

export default function LoginFooter() {
  return (
    <>
      <footer className="login-footer-main">
        Copyright &copy; {new Date().getFullYear()}{" "}
        <span className="login-fotr-title">{process.env.REACT_APP_API_SITE_TITLE}</span>. All rights reserved.
      </footer>
    </>
  );
}
