import axios from "axios";
import he from "he";

const API_URL = process.env.REACT_APP_API_Link;

const headers = {
  "Content-Type": "multipart/form-data",
};

const headers2 = {
  "Content-Type": "application/json",
};

const loginAdmin = (values) => {
  return axios.post(
    `${API_URL}admin/login`,
    {
      username: values.username,
      password: values.password,
    },
    {
      headers: headers2,
    }
  );
};

const forgotPass = (values) => {
  return axios.post(
    API_URL + "admin/forgot-password",
    {
      email: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const getCounts = () => {
  return axios.get(`${API_URL}admin/dashboard-counters`, {
    headers: headers2,
  });
};

const latestRewards = () => {
  return axios.get(`${API_URL}admin/dashboard-rewards`, {
    headers: headers2,
  });
};
const recentRewards = () => {
  return axios.get(`${API_URL}admin/dashboard-rewards`, {
    headers: headers2,
  });
};
const orderArchive = (adminId, orderId) => {
  return axios.put(
    API_URL + `archive-order/${orderId}`,
    {
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

const getProfile = (adminAccessToken) => {
  return axios.get(
    API_URL + "admin/get-profile",

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${adminAccessToken}`,
      },
    }
  );
};

const profileUpdate = (values, id) => {
  return axios.put(
    `${API_URL}admin/update/${id}`,
    {
      email: values.email,
      password: values.old_password,
      new_password: values.password,
      updated_by: id,
    },
    {
      headers: headers2,
    }
  );
};
const validateSSNAdmin = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}admin/check-ssn-exist/${values.id}/${values.ssn}`,
    {
      headers: headers,
    }
  );
};
const updateRsaList = (id, values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}admin/update-rsa/${id}`,
    {
      ssn: values.ssn,
      phone: values.phone,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      address: values.address,
      state_id: values.state,
      city_id: values.city,
      zip: values.zip,
      created_at: values.created_at,
      updated_by: values.updated_by,
    },
    {
      headers: headers,
    }
  );
};

const getUserState = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "list-state", {
    headers: headers,
  });
};

// !+
const getCityByStateId = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "list-city-by-state/" + id, {
    headers: headers,
  });
};
const getUserById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "admin/get-by/" + id, {
    headers: headers,
  });
};
const getWebsiteInfo = () => {
  return axios.get(
    API_URL + "admin/website",

    {
      headers: headers2,
    }
  );
};

const websiteUpdate = (values) => {
  return axios.put(
    API_URL + "admin/website",
    {
      email: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const getAllYears = () => {
  return axios.get(API_URL + `list-years`, {
    headers: headers2,
  });
};

const getAllGifts = () => {
  return axios.get(API_URL + `all-products`, {
    headers: headers2,
  });
};
const getAllLogos = () => {
  return axios.get(API_URL + `all-size`, {
    headers: headers2,
  });
};

const getAllRewards = (
  page,
  limit,
  values,
  startDate,
  endDate,
  sortCol,
  sortDir
) => {
  let query = `${API_URL}admin/rewards?limit=${limit}&page=${page}`;
  if (values?.fieldtype && values?.searchval) {
    query += `&${values.fieldtype}=${values.searchval}`;
  }

  if (sortCol && sortDir) {
    query += `&sort_column=${sortCol}&sort_direction=${sortDir}`;
  }

  if (startDate && endDate) {
    query += `&reg_start=${startDate}&reg_end=${endDate}`;
  }

  return axios.get(query, {
    headers: headers2,
  });
};

const deletSingleRewards = (id, admin_id) => {
  return axios.delete(API_URL + "admin/rewards/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const deletAllGifts = (admin_id) => {
  return axios.delete(API_URL + "products", {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const deletAllSize = (admin_id) => {
  return axios.delete(API_URL + "size", {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const getGiftById = (id) => {
  return axios.get(
    API_URL + "product/" + id,

    {
      headers: headers2,
    }
  );
};
const updateGiftById = (values, id) => {
  return axios.post(API_URL + `update-product/${id}`, values, {
    headers: headers,
  });
};

const addReward = (values) => {
  return axios.post(API_URL + `admin/upload-rewards`, values, {
    headers: headers,
  });
};

const getSizes = (page, limit, values) => {
  return axios.get(
    API_URL +
      `size?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const deletSingleSizes = (id, admin_id) => {
  return axios.delete(API_URL + "size/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const getSizeById = (id) => {
  return axios.get(
    API_URL + "size/" + id,

    {
      headers: headers2,
    }
  );
};
const updateSizeById = (values, id) => {
  return axios.post(API_URL + `update-size/${id}`, values, {
    headers: headers,
  });
};

const addSize = (values) => {
  return axios.post(API_URL + `size`, values, {
    headers: headers,
  });
};
const get1099Report = (
  page,
  limit,
  values,
  dateType,
  startDate,
  endDate,
  sortCol,
  sortDir
) => {
  let query = `${API_URL}admin/list-1099?limit=${limit}&page=${page}`;
  if (values?.fieldtype && values?.searchval) {
    query += `&${values.fieldtype}=${values.searchval}`;
  }

  if (sortCol && sortDir) {
    query += `&sort_column=${sortCol}&sort_direction=${sortDir}`;
  }

  if (dateType && startDate && endDate) {
    query += `&type=${dateType}`;
    if (dateType == "register") {
      query += `&reg_start=${startDate}&reg_end=${endDate}`;
    } else if (dateType == "rewards") {
      query += `&point_start=${startDate}&point_end=${endDate}`;
    }
  }

  return axios.get(query, {
    headers: headers2,
  });
};
const deletSingleOrders = (id, admin_id) => {
  return axios.delete(API_URL + "order/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const deletAllOrders = (admin_id) => {
  return axios.delete(API_URL + "orders", {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const archiveAllOrders = (admin_id) => {
  return axios.put(API_URL + "archive-all-orders", {
    headers: headers2,
    // data: {
    adminId: admin_id,
    // },
  });
};

// voucher
const getVouchers = (page, limit, values, status, year) => {
  return axios.get(
    API_URL +
      `vouchers/${year}/${status}?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const deletSingleRsa = (id, adminId) => {
  return axios.delete(API_URL + "admin/delete-rsa/" + id, {
    headers: headers2,
    data: {
      deleted_by: adminId,
    },
  });
};

const voucherArchive = (adminId, orderId) => {
  return axios.put(
    API_URL + `archive-voucher/${orderId}`,
    {
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};
const voucherUnArchive = (adminId, orderId) => {
  return axios.put(
    API_URL + `unarchive-voucher/${orderId}`,
    {
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

const updateVoucherById = (values, adminId, id, color, size) => {
  return axios.put(
    API_URL + `voucher-ps/${id}`,
    {
      company: values.Company,
      job: values.Job,
      certificate: values.Certificate,
      expiration: values.Expiration,
      concatenated: values.Concatenated,
      amount: values.Amount,
      shipCost: values.shipCost,
      shipped: values.Shipped,
      received: values.Received,
      fulfilled: values.Fulfilled,

      fname: values.First,
      lname: values.Last,
      street: values.Street,
      city: values.City,
      state: values.State,
      zip: values.Zip,
      issuance: values.issuance,
      denomination: values.Denomination,
      color: color,
      size: size,
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

// EMAILS APIS
const getEmails = (page, limit, values) => {
  return axios.get(
    API_URL +
      `emails?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const deletSingleEmail = (id, admin_id) => {
  return axios.delete(API_URL + "email/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const deletAllEmail = (admin_id) => {
  return axios.delete(API_URL + "emails", {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const getEmailById = (id) => {
  return axios.get(
    API_URL + "email/" + id,

    {
      headers: headers2,
    }
  );
};
const updateEmailById = (email, id, adminId) => {
  return axios.put(
    API_URL + `email/${id}`,
    {
      email: email,
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

const addEmail = (email, adminId) => {
  return axios.post(
    API_URL + `email`,
    {
      email: email,
      adminId: adminId,
    },
    {
      headers: headers,
    }
  );
};

// logos section .

const getLogos = (page, limit, values) => {
  return axios.get(
    API_URL +
      `logos?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};

// job api

const listRsa = (page, limit, values, sortCol, sortDir) => {
  let query = `${API_URL}admin/list-rsa?limit=${limit}&page=${page}`;
  if (values.fieldtype && values.searchval) {
    query += `&${values.fieldtype}=${values.searchval}`;
  }

  if (sortCol && sortDir) {
    query += `&sort_column=${sortCol}&sort_direction=${sortDir}`;
  }
  return axios.get(query, {
    headers: headers2,
  });
};

const deleteSelectedRsa = (recordId, adminId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(`${API_URL}admin/delete-selected-rsa/${recordId}`, {
    headers: headers,
    data: {
      deleted_by: adminId,
    },
  });
};
const getJobDetailByJob = (page, limit, values, job) => {
  return axios.get(
    API_URL +
      `job-detail/${job}?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const getJobDetailInfoByJob = (job) => {
  return axios.get(API_URL + `job-detail-info/${job}`, {
    headers: headers2,
  });
};

const uploadSheet = (values) => {
  return axios.post(API_URL + `upload-sheet-ps`, values, {
    headers: headers,
  });
};

const getNewsletter = (orderId) => {
  return axios.get(
    API_URL + `newsletter/${orderId}`,

    {
      headers: headers2,
    }
  );
};

const newsletterUpdate = (values, admin_id, id) => {
  return axios.put(
    API_URL + "newsletter/" + id,
    {
      subject: values.subject,
      body: he.encode(values.body),
      adminId: admin_id,
    },
    {
      headers: headers2,
    }
  );
};

const sendMailJob = (job) => {
  return axios.get(`${API_URL}send-mail-job/${job}`, {
    headers: headers2,
  });
};

const ip = await axios.get("https://geolocation-db.com/json/");
const newProductAddition = async (values) => {
  return axios.post(
    API_URL + "admin/add-product",
    {
      name: values.prdName,
      number: values.prdNo,
      created_ip: ip.data.IPv4,
    },
    {
      headers: headers2,
    }
  );
};

const viewProducts = (page, limit, values, sortCol, sortDir) => {
  let query = `${API_URL}admin/list-product?limit=${limit}&page=${page}`;
  if (values.fieldtype && values.searchval) {
    query += `&${values.fieldtype}=${values.searchval}`;
  }

  if (sortCol && sortDir) {
    query += `&sort_column=${sortCol}&sort_direction=${sortDir}`;
  }
  return axios.get(query, {
    headers: headers2,
  });
};

const deletSingleProduct = (id, admin_id) => {
  return axios.delete(API_URL + "admin/delete-product/" + id, {
    headers: headers2,
    data: {
      deleted_ip: ip.data.IPv4,
      deleted_by: admin_id,
    },
  });
};

const listAllProducts = () => {
  return axios.get(`${API_URL}admin/list-all-product`, {
    headers: headers2,
  });
};
const getSizeByProductId = (id) => {
  return axios.get(`${API_URL}admin/list-size-by-product/${id}`, {
    headers: headers2,
  });
};

const addSizeForProduct = async (values, id) => {
  return axios.post(
    API_URL + "admin/add-size",
    {
      prod_id: values.prdName,
      size: values.prdSize,
      price: values.prdPrice,
      created_ip: ip.data.IPv4,
      created_by: id,
    },
    {
      headers: headers2,
    }
  );
};

const productPriceBySizeId = (id) => {
  return axios.get(`${API_URL}admin/get-price-by-size/${id}`, {
    headers: headers2,
  });
};

const updatePriceForProductSize = async (values, id, sizeId) => {
  return axios.put(
    API_URL + "admin/update-product/" + sizeId,
    {
      price: values.prdPrice,
      updated_ip: ip.data.IPv4,
      updated_by: id,
    },
    {
      headers: headers2,
    }
  );
};

const AdminListService = {
  sendMailJob,
  loginAdmin,
  forgotPass,
  getCounts,
  latestRewards,
  recentRewards,
  getProfile,
  profileUpdate,
  validateSSNAdmin,
  updateRsaList,
  getCityByStateId,
  getUserState,
  getUserById,
  orderArchive,
  getWebsiteInfo,
  websiteUpdate,
  getAllRewards,
  getAllGifts,
  getAllYears,
  getAllLogos,
  deletSingleRewards,
  deletAllGifts,
  deletAllSize,
  getGiftById,
  updateGiftById,
  addReward,
  getSizes,
  getSizeById,
  deletSingleSizes,
  updateSizeById,
  addSize,
  get1099Report,
  deletSingleOrders,
  deletAllOrders,
  archiveAllOrders,
  voucherUnArchive,
  updateVoucherById,
  deletSingleRsa,
  getVouchers,
  voucherArchive,
  getEmails,
  deletAllEmail,
  deletSingleEmail,
  getEmailById,
  updateEmailById,
  addEmail,
  getLogos,
  listRsa,
  deleteSelectedRsa,
  getJobDetailByJob,
  getJobDetailInfoByJob,
  uploadSheet,
  getNewsletter,
  newsletterUpdate,
  newProductAddition,
  viewProducts,
  deletSingleProduct,
  listAllProducts,
  getSizeByProductId,
  addSizeForProduct,
  productPriceBySizeId,
  updatePriceForProductSize,
};

export default AdminListService;
