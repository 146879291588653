import React, { useEffect, useRef } from "react";
import Footer from "../../user/Dashboard/includes/Footer";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";
import ReactLoading from "react-loading";
import MainHeading from "../../user/Dashboard/includes/MainHeading";
import { useFormik } from "formik";
import { updateProfile } from "../../../schema";
import Header from "./includes/UserHeader";
import UserService from "../../../services/user.service";
import UserHeader from "./includes/UserHeader";
import Swal from "sweetalert2";

export default function UserProfile() {
  const [loading, setLoading] = useState(false);
  const passRef = useRef();
  const state = useSelector((state) => state.stateVals);
  const { accessToken, id } = state;
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      const profileInfo = async () => {
        setLoading(true);
        try {
          const response = await UserService.getProfile(accessToken);

          setValues({
            email: response.data.email,
          });
          setLoading(false);
        } catch (err) {
          setLoading(false);

          if (
            err?.response?.data?.message &&
            typeof err?.response?.data?.message === "string"
          ) {
            toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error("Something went wrong, try again", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        }
      };
      profileInfo();
    } else {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const updateProfileFun = async (values, action) => {
    setLoading(true);
    try {
      const response = await UserService.profileUpdate(values, id);
      // passRef.current.value = "";

      toast.success("Update Profile!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      window.location.reload();
      setLoading(false);
    } catch (err) {
      setLoading(false);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.error(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirm_password: "",
      old_password: "",
    },
    validationSchema: updateProfile,
    onSubmit: (values) => {
      updateProfileFun(values);
    },
  });

  return (
    <div className="wrapper">
      <div className="container">
        <main className="page-content customer-contnent">
          <div className="user-main-design">
            <div className="user-heading-sectiom">
              Profile <span className="p-color">Information</span>
              <div className="clearfix"></div>
            </div>

            <div className="row">
              <div className="mt-4 col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="card dashboard-cards">
                  <div className="bg-white  py-3 card-header">
                    <h4 className="mb-0">Account Details</h4>
                  </div>
                  <div className="card-body">
                    <form className="row" onSubmit={handleSubmit} noValidate>
                      <div className="col-12 mb-3">
                        <div
                          className="alert alert-primary d-flex align-items-center font-17 "
                          role="alert"
                        >
                          <svg
                            width="1em"
                            height="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                            viewBox="0 0 16 16"
                            role="img"
                            fill="#052c65"
                            aria-label="Warning:"
                            style={{ marginTop: "-2px" }}
                          >
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                          </svg>
                          <div>
                            {" "}
                            A correct email address is important for informing
                            you of program specials, events, promotions, etc...
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="input-field-box">
                          <label>
                            E-mail <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="E-mail"
                            onChange={handleChange}
                            name="email"
                            value={values.email || ""}
                            readOnly={true}
                          />
                          {errors.email && touched.email ? (
                            <span className="text-danger">{errors.email}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div
                          className="alert alert-primary d-flex align-items-center font-17 "
                          role="alert"
                        >
                          <svg
                            width="1em"
                            height="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                            viewBox="0 0 16 16"
                            role="img"
                            fill="#052c65"
                            aria-label="Warning:"
                            style={{ marginTop: "-2px" }}
                          >
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                          </svg>
                          <div>
                            {" "}
                            Fill in the following fields if you would like to
                            change your password.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-field-box mb-md-0">
                          <label>Current Password</label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Current  Password"
                            onChange={handleChange}
                            name="old_password"
                            value={values.old_password || ""}
                            ref={passRef}
                          />
                          {errors.old_password && touched.old_password ? (
                            <span className="text-danger">
                              {errors.old_password}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-field-box mb-md-0">
                          <label>New Password</label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="New Password"
                            onChange={handleChange}
                            name="password"
                            value={values.password || ""}
                            ref={passRef}
                          />
                          {errors.password || touched.password ? (
                            <span className="text-danger">
                              {errors.password}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-field-box mb-0">
                          <label>Verify New Password</label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Verify New Password"
                            onChange={handleChange}
                            name="confirm_password"
                            value={values.confirm_password || ""}
                          />
                          {errors.confirm_password ||
                          touched.confirm_password ? (
                            <span className="text-danger">
                              {errors.confirm_password}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-primary mt-3" type="submit">
                          Update Profile
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </main>
      </div>
      <Footer />
      {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#6c44c7"
          className="bar-loader"
        />
      )}
    </div>
  );
}
