import secureLocalStorage from "react-secure-storage";

// secureLocalStorage.setItem("acessTokens", JSON.stringify(acessTokens));

// let retrievedObject = JSON.parse(secureLocalStorage.getItem("acessTokens"));

// const adminAccessToken = retrievedObject?.adminAccessToken;
// if (!retrievedObject) {
//   retrievedObject = {};
// }
const accessToken = secureLocalStorage.getItem("accessToken");
const adminAccessToken = secureLocalStorage.getItem("adminAccessToken");
const id = secureLocalStorage.getItem("_id");
const adminId = secureLocalStorage.getItem("adminId");
const uType = secureLocalStorage.getItem("uType");
const username = secureLocalStorage.getItem("uName");
const name = secureLocalStorage.getItem("name");
const adminName = secureLocalStorage.getItem("adminName");

const initialState = {
  accessToken: accessToken ? accessToken : undefined,
  adminAccessToken: adminAccessToken ? adminAccessToken : undefined,
  id: id ? id : undefined,
  adminId: adminId ? adminId : undefined,
  uType: uType ? uType : undefined,
  username: username ? username : undefined,
  name: name ? name : undefined,
  adminName: adminName ? adminName : undefined,
};

const reducer = (state = initialState, action) => {
  if (action.type === "login") {
    secureLocalStorage.setItem("accessToken", action.payload.accessToken);
    secureLocalStorage.setItem("_id", action.payload.id);
    secureLocalStorage.setItem("uType", action.payload.uType);
    secureLocalStorage.setItem("uName", action.payload.uName);
    secureLocalStorage.setItem("name", action.payload.name);

    return {
      ...state,
      accessToken: action.payload.accessToken,
      id: action.payload.id,
      uType: action.payload.uType,
      uName: action.payload.uName,
      name: action.payload.name,
    };
  } else if (action.type === "adminLogin") {
    secureLocalStorage.setItem(
      "adminAccessToken",
      action.payload.adminAccessToken
    );
    secureLocalStorage.setItem("adminId", action.payload.adminId);
    secureLocalStorage.setItem("adminName", action.payload.adminName);
    // secureLocalStorage.setItem("uType", action.payload.uType);

    return {
      ...state,
      adminAccessToken: action.payload.adminAccessToken,
      adminId: action.payload.adminId,
      adminName: action.payload.adminName,
      // uType: action.payload.uType,
    };
  } else if (action.type === "updateprofile") {
    secureLocalStorage.setItem("_id", action.payload.id);
    // secureLocalStorage.setItem("uType", action.payload.uType);
    secureLocalStorage.setItem("uName", action.payload.uName);
    secureLocalStorage.setItem("name", action.payload.name);
    return {
      ...state,
      id: action.payload.id,
      // uType: action.payload.uType,
      uName: action.payload.uName,
      name: action.payload.name,
    };
  } else if (action.type === "updatename") {
    secureLocalStorage.setItem("name", action.payload.name);
    return {
      ...state,
      name: action.payload.name,
    };
  } else if (action.type === "logout") {
    secureLocalStorage.removeItem("accessToken");
    secureLocalStorage.removeItem("id");
    secureLocalStorage.removeItem("uName");
    secureLocalStorage.removeItem("name");
    return {
      ...state,
      accessToken: null,
      id: null,
      uName: null,
      name: null,
    };
  } else if (action.type === "adminLogout") {
    secureLocalStorage.removeItem("adminAccessToken");
    secureLocalStorage.removeItem("adminId");
    secureLocalStorage.removeItem("adminName");

    return {
      ...state,
      adminAccessToken: null,
      adminId: null,
      adminName: null,
    };
  } else if (action.type === "forgot") {
    secureLocalStorage.setItem("forgotToken", action.payload.forgotToken);
    return {
      // ...state,
      forgotToken: action.payload.forgotToken,
    };
  } else if (action.type === "removeForgot") {
    secureLocalStorage.removeItem("forgotToken");
    return {
      // ...state,
      forgotToken: null,
    };
  } else {
    return state;
  }
};

export default reducer;
