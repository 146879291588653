import React, { useEffect, useRef } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";
import Skeleton from "react-loading-skeleton";
import Pagination from "react-js-pagination";
import useTable from "../../../hooks/useTable";
import useSortableData from "../../../hooks/useSortableData";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import moment from "moment";
import formatThousands from "format-thousands";
import Swal from "sweetalert2";
import $ from "jquery";
import { toast } from "react-toastify";

export default function ViewRewards2() {
  const [tableLoader, setTableLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState("10");
  const [sortCol, setSortCol] = useState("true");
  const [sortDir, setSortDir] = useState("true");
  const [sortColErr, setSortColErr] = useState("");
  const [sortDirErr, setSortDirErr] = useState("");
  const [dirDisabled, setDirDisabled] = useState(true);
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("0");
  const date = new Date();
  var time = date.getTime();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isRecords, setIsRecords] = useState(true);
  const [isExport, setIsExport] = useState(true);
  const [isEndDate, setIsEndDate] = useState(false);
  const [stGradient, setStGradient] = useState("gradient-border");
  const [endGradient, setEndGradient] = useState("");

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, adminId } = state;
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_Link;

  const dateRef = useRef(null);
  const endDateRef = useRef(null);

  const styles = {
    range: {
      pointerEvents: isExport ? "none" : "",
      opacity: isExport ? "0.6" : "1",
    },
  };

  // ~Start Date...............
  const changeStartDate = (e) => {
    setStGradient("");
    setEndGradient("gradient-border");
    let date = e;
    setStartDate(date);
    setIsRecords(true);
    setIsExport(true);
    endDateRef.current.value = "";

    setIsEndDate(true);
  };
  //~.....................

  // ~End Date...............
  const changeEndDate = (e) => {
    setEndGradient("");
    let date = e;
    setEndDate(date);
    setIsRecords(false);
    setIsExport(false);
    let startSplit = startDate.split("-");
    let endSplit = date.split("-");
    let date1 = new Date(startSplit[0], startSplit[1], startSplit[2]);
    let date2 = new Date(endSplit[0], endSplit[1], endSplit[2]);
    if (date2 < date1) {
      endDateRef.current.value = "";
      toast.error("Ending date must be greater than starting date!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  //~.....................

  var singleSelections = "";
  var allSelections = "";
  var time = date.getTime();

  // FIXME auto unselect single selection
  const changeSelection = () => {
    $(".slect_all").prop("checked", false);

    let selectedRecords = $(".table-style-1 tbody tr td input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join("+");
    singleSelections = selectedRecords;
    $(".export-selection").attr(
      "href",
      `${process.env.REACT_APP_API_Link}user/export-selected-rsa/${selectedRecords}?time=${time}`
    );
  };

  // FIXME auto unselect issue
  const allSelect = () => {
    const selectAllCheckbox = $(".slect_all");
    const singleCheckboxes = $("input[name='single']:checkbox");

    selectAllCheckbox.prop("checked", !selectAllCheckbox.prop("checked"));
    singleCheckboxes.prop("checked", selectAllCheckbox.prop("checked"));

    const checkedVals = $(".select_one:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get();

    allSelections = checkedVals.join("+");

    $(".export-selection").attr(
      "href",
      `${process.env.REACT_APP_API_Link}user/export-selected-rsa/${allSelections}?time=${time}`
    );
  };

  const rewardsLists = async (page, limit, values, sortCol, sortDir) => {
    if (sortCol && sortCol != "true") {
      setDirDisabled(false);
      setSortCol(sortCol);
    }
    if (sortDir) {
      setSortDir(sortDir);
    }

    if (sortCol != "true" && sortDir == "true") {
      setSortDirErr("Please select this");
      return;
    } else if (sortCol == "true" && sortDir != "true") {
      setSortColErr("Please select this");
      return;
    } else if (sortCol != "true" && sortDir != "true") {
      setSortColErr("");
      setSortDirErr("");
    }

    // if (startDate && endDate) {

    // }

    setTableLoader(true);
    try {
      const response = await AdminListService.getAllRewards(
        page,
        limit,
        values,
        dateRef.current.value,
        endDateRef.current.value,
        sortCol,
        sortDir
      );

      const res = response?.data?.response;
      const results = [];
      res.map((value) => {
        return results.push({
          sale_id: value.sale_id,
          store: value.store,
          sale_name: value.sale_name,
          points: value.points,
          created_at: value.created_at,
          id: value.id,
        });
      });

      setTableData([...results]);
      setTotalPages(response?.data?.total_pages);
      setTotalResults(response?.data?.records);
      setCurrentPage(response?.data?.current_page);
      setLimit(response?.data?.per_page);
      setTableLoader(false);
    } catch (err) {
      setTableLoader(false);
      setTableData([]);
      setTotalPages("0");
      setTotalResults("0");
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      rewardsLists("1", limit, []);
    } else {
      navigate("/admin/login", { replace: true });
    }

    // eslint-disable-next-line
  }, []);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        fieldtype: "job",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values) => {
        rewardsLists("1", limit, values);
      },
    });

  const deleteSelected = async () => {
    var counter = singleSelections.length;
    if (counter > 0) {
      setLoading(true);
      try {
        await AdminListService.deleteSelectedRsa(singleSelections, adminId);
        setLoading(false);
        rewardsLists("1", limit, []);

        toast.success("Record(s) has been deleted!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (err) {
        setLoading(false);
      }
    } else {
      toast.error("Please select some record!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    // getResultData();
  };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        {tableLoader ? (
          <div className="card-body pt-0">
            <Skeleton count={11} height="50px" />
          </div>
        ) : (
          <div className="table-responsive">
            <table className="text-nowrap table">
              <thead className="table-light">
                <tr>
                  <th
                    scope="col"
                    onClick={allSelect}
                    // className={getClassNamesFor("Select")}
                  >
                    <label htmlFor="Select">
                      <input
                        id="Select"
                        className="form-check-input slect_all"
                        type="checkbox"
                        onClick={allSelect}
                      />
                    </label>
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("id")}
                    className={getClassNamesFor("id")}
                  >
                    No
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("sale_id")}
                    className={getClassNamesFor("sale_id")}
                  >
                    Rsa Id
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("store")}
                    className={getClassNamesFor("store")}
                  >
                    Store
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("sale_name")}
                    className={getClassNamesFor("sale_name")}
                  >
                    Sales Person Name
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("points")}
                    className={getClassNamesFor("points")}
                  >
                    Reward Amount
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("created_at")}
                    className={getClassNamesFor("created_at")}
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length ? (
                  data.map((el, index) => (
                    <tr key={index}>
                      <td className="align-middle">
                        <input
                          className="form-check-input select_one"
                          name="single"
                          id={el.id}
                          value={el.id}
                          type="checkbox"
                          onChange={changeSelection}
                        />
                      </td>
                      <td className="align-middle">
                        {currentPage === 1
                          ? index + 1
                          : (currentPage - 1) * limit + index + 1}
                      </td>
                      <td className="align-middle">
                        {el.sale_id ? el.sale_id : "N/A"}
                      </td>
                      <td className="align-middle">
                        {el.store ? el.store : "N/A"}
                      </td>
                      <td className="align-middle">
                        {el.sale_name ? el.sale_name : "N/A"}
                      </td>
                      <td className="align-middle">
                        $ {el.points ? el.points : "N/A"}
                      </td>
                      <td className="align-middle">
                        {moment(el.created_at).format("MM-DD-YYYY")}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center text-capitalize">
                      No record found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {totalResults > limit && totalPages > 1 ? (
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={parseInt(limit)}
                totalItemsCount={totalResults}
                onChange={(e) => {
                  rewardsLists(e, limit, values, sortCol, sortDir);
                }}
                pageRangeDisplayed={8}
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First Page"
                lastPageText="Last Page"
              />
            ) : (
              ""
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="heading-sectiom">View Rsa's</div>
          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <div className="col-md-8">
                      <h4 className="mb-0">
                        Total Rsa's:{" "}
                        <span className="text-primary-color fw-bold">
                          {formatThousands(totalResults, {
                            separator: ",",
                          })}
                        </span>
                      </h4>
                    </div>
                    {/* <div className="col-md-4 text-end">
                      <a
                        className="btn btn-primary btn-sm btn-icon-text"
                        href={`${process.env.REACT_APP_API_Link}admin/export-all-rsa?time=${currentDateTime}`}
                      >
                        Export All RSA{" "}
                        <i className="bi bi-filetype-exe"></i>
                      </a>
                    </div> */}
                    <div className="col-md-4 text-end">
                      <li className="dropdown">
                        <button
                          className="btn btn-primary back-blue dropdown-toggle viewRSA"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          id="RsaAction"
                        >
                          Action
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item delete-selection"
                              name="delete_selected"
                              href="javascript:void(0)"
                              onClick={(e) => {
                                deleteSelected(e);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Delete Selected
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item export-selection"
                              name="export_selected"
                              href="javascript:void(0)"
                              style={{ cursor: "pointer" }}
                            >
                              Export Selected
                            </a>
                          </li>
                          <li>
                            <a
                              href={`${process.env.REACT_APP_API_Link}admin/export-all-rsa?time=${currentDateTime}`}
                              className="dropdown-item"
                              style={{ cursor: "pointer" }}
                            >
                              Export All
                            </a>
                          </li>
                        </ul>
                      </li>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-lg-3">
                      <div className="input-field-box mb-4">
                        <label>Register Start Date</label>
                        <div className="reacter-datepicker">
                          <input
                            type="date"
                            name="start"
                            ref={dateRef}
                            className={`form-control ${stGradient}`}
                            onChange={(e) => {
                              changeStartDate(e.target.value);
                            }}
                            placeholder="mm-dd-yyyy"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="input-field-box mb-4">
                        <label className="spiff-search">
                          Register End Date
                        </label>
                        <div className="input-group">
                          <input
                            type="date"
                            name="end"
                            ref={endDateRef}
                            className={`form-control ${endGradient}`}
                            onChange={(e) => changeEndDate(e.target.value)}
                            placeholder="mm-dd-yyyy"
                            disabled={!isEndDate}
                          />
                          <button
                            className="btn btn-primary"
                            onClick={(e) => rewardsLists("1", limit)}
                            disabled={isRecords}
                            type="button"
                          >
                            Show records
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <a
                        className="btn btn-primary back-orange export-range"
                        onClick={isExport}
                        style={styles.range}
                        href={`${API_URL}admin/export-range-rewards/${startDate}/${endDate}`}
                      >
                        Export by range
                      </a>
                    </div>

                    <div className="col-lg-2">
                      <div className="input-field-box mb-lg-0">
                        <label>Show Column</label>
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="">Select Column</option>
                          <option value="sale_id">Rsa Id</option>
                          <option value="store">Store</option>
                          <option value="sale_name">Sales Person Name</option>
                          <option value="points">Rewards Amount</option>
                          <option value="created_at">Register Date</option>
                        </select>
                      </div>
                      {errors.fieldtype && touched.fieldtype ? (
                        <span className="text-danger">{errors.fieldtype}</span>
                      ) : null}
                    </div>

                    <div className="col-lg-4">
                      <div className="input-field-box mb-lg-0">
                        <label className="spiff-search">Search</label>
                        <div className="input-group">
                          <input
                            type={
                              values.fieldtype === "created_at" ||
                              values.fieldtype === "cron_date"
                                ? "date"
                                : "text"
                            }
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            name="searchval"
                            value={values.searchval || ""}
                            required
                          />

                          <button className="btn btn-primary " type="submit">
                            Search
                          </button>
                          <button
                            className="btn btn-primary"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      {errors.searchval && touched.searchval ? (
                        <span className="text-danger">{errors.searchval}</span>
                      ) : null}
                    </div>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-0">
                        <label>Show Entries</label>
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) =>
                            rewardsLists("1", e.target.value, values)
                          }
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="input-field-box mb-0">
                        <label>Sort By Column</label>
                        <select
                          className="form-select"
                          value={sortCol}
                          name="sort_column"
                          onChange={(e) =>
                            rewardsLists(
                              "1",
                              limit,
                              values,
                              e.target.value,
                              sortDir
                            )
                          }
                        >
                          <option value>Select Column</option>
                          <option value="sale_id">Rsa Id</option>
                          <option value="store">Store</option>
                          <option value="sale_name">Sales Person Name</option>
                          <option value="points">Rewards Amount</option>
                          <option value="created_at">Register Date</option>
                        </select>
                        {sortColErr && (
                          <span className="text-danger">{sortColErr}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="input-field-box mb-0">
                        <label>Sort By Direction</label>
                        <select
                          disabled={dirDisabled}
                          className="form-select"
                          value={sortDir}
                          onChange={(e) =>
                            rewardsLists(
                              "1",
                              limit,
                              values,
                              sortCol,
                              e.target.value
                            )
                          }
                        >
                          <option value>Select Direction</option>
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                        {sortDirErr && (
                          <span className="text-danger">{sortDirErr}</span>
                        )}
                      </div>
                    </div>
                  </form>
                </div>

                {tableLoader ? (
                  <div className="card-body">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <Table data={tableData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
