import React, { useEffect, useLayoutEffect } from "react";

import { Link, useMatch, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import ReactLoading from "react-loading";
import { useFormik } from "formik";
import { useMask } from "@react-input/mask";
import Logo from "../../../Images/logo.webp";
import { signUpSchema } from "../../../schema";
import ReCAPTCHA from "react-google-recaptcha";
import UserService from "../../../services/user.service";
import Select from "react-select";
import ToTop from "../../admin/includes/ToTop";
import Swal from "sweetalert2";

export default function Register() {
  const match = useMatch("/register", { caseSensitive: false });

  useLayoutEffect(() => {
    if (match?.pattern?.path === match?.pathname) {
    } else {
      window.location.href = "/register";
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [termsModel, setTermsModel] = useState(false);
  const [errorsCapMsg, setErrorsCapMsg] = useState("");
  const [errorsCap, setErrorsCap] = useState(null);

  const [fieldError, setFieldError] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address1: "",
    address2: "",
    ssn: "",
    zip: "",
    state: "",
    city: "",
    password: "",
    signature: "",
  });

  const [userState, setUserState] = useState([]);
  const [userCity, setUserCity] = useState([]);
  const [userCityLoader, setUserCityLoader] = useState(false);
  const [usererror, setUsererror] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const getUserState = async () => {
      const { data } = await UserService.getUserState();
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          label: value.name,
          value: value.id,
        });
      });
      setUserState([...results]);
    };

    getUserState();
  }, []);

  const changeUserCity = (state) => {
    setUserCity([]);

    const getUserCity = async () => {
      setUserCityLoader(true);
      const { data } = await UserService.getCityByStateId(state);
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          label: value.city,
          value: value.id,
        });
      });
      await setUserCity([...results]);
      setUserCityLoader(false);
    };

    if (state !== "") {
      getUserCity();
    }
  };

  const submission = async () => {
    setLoading(true);
    try {
      const response = await UserService.validateRegister(values);

      if (response.status === 201) {
        setLoading(false);
        setTermsModel(false);

        toast.success("Registered, Login Now!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          navigate("/thankyou");
        }, 2000);
        // action.resetForm();
      }
    } catch (err) {
      setTermsModel(false);

      if (err?.response?.status === 401) {
        setLoading(false);

        toast.error("Error, Try Again Later!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.status === 403) {
        setLoading(false);

        toast.error("Sorry, you are not allowed to register", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.status === 422) {
        setLoading(false);

        setFieldError((prevErrors) => ({
          ...prevErrors,
          first_name: err?.response?.data?.first_name?.[0] || "",
          last_name: err?.response?.data?.last_name?.[0] || "",
          email: err?.response?.data?.email?.[0] || "",
          phone: err?.response?.data?.phone?.[0] || "",
          address1: err?.response?.data?.address1?.[0] || "",
          address2: err?.response?.data?.address2?.[0] || "",
          ssn: err?.response?.data?.ssn?.[0] || "",
          zip: err?.response?.data?.zip?.[0] || "",
          state: err?.response?.data?.state_id?.[0] || "",
          city: err?.response?.data?.city_id?.[0] || "",
          signature: err?.response?.data?.signature?.[0] || "",
          password: err?.response?.data?.password?.[0] || "",
        }));

        // setFieldError();

        toast.error("Error, Unprocessable Content!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoading(false);

        toast.error("Error, Some thing went wrong!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        fname: "",
        lname: "",
        ssn: "",
        address1: "",
        address2: "",
        state_id: "",
        city_id: "",
        password: "",
        confirm_password: "",
        zipcode: "",
        phone: "",
        signature: "",
        iAgree: false,
        termsAndConditions: false,
      },
      validationSchema: signUpSchema,
      onSubmit: () => {
        if (usererror === "") {
          console.log(errorsCap);
          if (errorsCap !== null) {
            setTermsModel(true);
            setErrorsCapMsg("");
          } else {
            setErrorsCapMsg("Captcha is not verified");
          }
        }
      },
    });

  const checkSSN = async (e) => {
    let ssn = e.currentTarget.value;
    if (ssn !== "" && ssn.length >= 11) {
      try {
        const response = await UserService.validateSSN(ssn);

        setFieldError((prevErrors) => ({
          ...prevErrors,
          ssn: "",
        }));
      } catch (err) {
        setFieldError((prevErrors) => ({
          ...prevErrors,
          ssn: err?.response?.data?.ssn?.[0] || "",
        }));
      }
    } else {
      setFieldError((prevErrors) => ({
        ...prevErrors,
        ssn: "",
      }));
    }
  };

  const checkuser = async () => {
    if (values.email !== "") {
      try {
        const response = await UserService.validateUser(values);

        if (response.status === 200) {
          setUsererror("");
        }
      } catch (err) {
        if (err.response.status === 422) {
          setUsererror(err.response.data.response);
        } else {
          Swal.fire({
            title: "Error!",
            text: "Some thing went wrong!",
            icon: "error",
            confirmButtonText: "Try Again",
          });
        }
      }
    } else {
      setUsererror("");
    }
  };

  function onChange(value) {
    setErrorsCap(value);
    if (value === null) {
      setErrorsCapMsg("Captcha is not verified");
    } else {
      setErrorsCapMsg("");
    }
  }

  const ref = useMask({ mask: "___-__-____", replacement: { _: /\d/ } });
  const handleButtonClick = () => {
    setTimeout(() => {
      const firstError = document.querySelector(".errors-fields");
      if (firstError) {
        // firstError.scrollIntoView({ behavior: "smooth" });
        const rect = firstError.getBoundingClientRect();
        const offsetTop = rect.top - 200 + window.scrollY;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    }, 500);
  };

  const hideMod = () => {
    setTermsModel(false);
  };

  return (
    <div className="wrapper">
      <div className="container">
        <main className="page-content customer-contnent">
          <div className="user-main-design">
            <header className="main-header">
              <nav className="navbar navbar-expand-lg navbar-light flex-column">
                <Link className="navbar-brand" to="/">
                  <img src={Logo} className="login-logo register" alt="" />
                </Link>
                <div className="alred-memb">
                  Already a member?
                  <Link className="round-red-btn" to="/">
                    LOGIN
                  </Link>
                </div>
              </nav>
            </header>
            <div className="user-heading-sectiom">
              REGISTRATION <span className="p-color">FORM</span>
              <div className="clearfix"></div>
            </div>

            <div className="row">
              <div className="mt-4 col-xl-12 col-lg-12 col-md-12 col-12">
                <form className="row" onSubmit={handleSubmit} noValidate>
                  <ul className="timeline claim_detail">
                    <li>
                      <div className="prfil-set">
                        <div className="form-box">
                          <div className="row">
                            <div className="col-lg-12">
                              <div
                                className="alert alert-danger d-flex align-items-center font-17 "
                                role="alert"
                              >
                                <svg
                                  width="1em"
                                  height="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                  viewBox="0 0 16 16"
                                  role="img"
                                  fill="#58151c"
                                  aria-label="Warning:"
                                  style={{ marginTop: "-2px" }}
                                >
                                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </svg>
                                <div>
                                  {" "}
                                  Field(s) marked with Asterisk (*) are
                                  mandatory.
                                </div>
                              </div>
                              <h4 className="prf-hed">
                                SELECT EMAIL AND PASSWORD
                              </h4>{" "}
                              <ul className="circle-list">
                                <li>
                                  <span className="circle">❍ </span>
                                  Your password must be at least 6 characters
                                  long. For example a uppercase letter, a
                                  lowercase and digits.
                                </li>
                              </ul>
                              <br />
                            </div>
                          </div>

                          <div className="form-filds">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    E-mail{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    checkUser={checkuser}
                                    className="form-control"
                                    placeholder="E-mail"
                                    onChange={handleChange}
                                    name="email"
                                    value={values.email || ""}
                                  />
                                  {errors.email && touched.email ? (
                                    <span className="text-danger">
                                      {errors.email}
                                      {usererror ? (
                                        <p className="help text-danger errors-fields">
                                          {usererror}
                                        </p>
                                      ) : null}
                                    </span>
                                  ) : null}
                                  {fieldError.email && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.email}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    Password{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    onChange={handleChange}
                                    name="password"
                                    value={values.password || ""}
                                  />
                                  {errors.password && touched.password ? (
                                    <span className="text-danger errors-fields">
                                      {errors.password}
                                    </span>
                                  ) : null}
                                  {fieldError.password && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.password}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="input-field-box mb-md-0">
                                  <label>
                                    Confirm Password
                                    <span className="text-danger"> *</span>
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Confirm  Password"
                                    onChange={handleChange}
                                    name="confirm_password"
                                    value={values.confirm_password || ""}
                                  />
                                  {errors.confirm_password &&
                                  touched.confirm_password ? (
                                    <span className="text-danger errors-fields">
                                      {errors.confirm_password}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="prfil-set">
                        <div className="form-box">
                          <div className="row">
                            <div className="col-lg-12">
                              <h4 className="prf-hed">
                                ENTER PERSONAL INFORMATION
                              </h4>{" "}
                              <ul className="circle-list">
                                <li>
                                  <span className="circle">❍ </span>
                                  Please note this information will be used for
                                  your 1099 form as well therefore make sure you
                                  enter the right information. You are not
                                  eligible for a card until you enter SSN.
                                </li>
                                <li>
                                  <span className="circle">❍ </span>
                                  We only accept residential address, please do
                                  not enter P.O Box Number or commercial
                                  addresses.
                                </li>
                              </ul>
                              <br />
                            </div>
                          </div>

                          <div className="form-filds">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    First Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    onChange={handleChange}
                                    name="fname"
                                    value={values.fname || ""}
                                  />
                                  {errors.fname && touched.fname ? (
                                    <span className="text-danger errors-fields">
                                      {errors.fname}
                                    </span>
                                  ) : null}
                                  {fieldError.first_name && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.first_name}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    Last Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    onChange={handleChange}
                                    name="lname"
                                    value={values.lname || ""}
                                  />
                                  {errors.lname && touched.lname ? (
                                    <span className="text-danger errors-fields">
                                      {errors.lname}
                                    </span>
                                  ) : null}
                                  {fieldError.last_name && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.last_name}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    SSN <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="XXX-XX-XXXX"
                                    onChange={handleChange}
                                    onKeyUp={checkSSN}
                                    name="ssn"
                                    value={values.ssn || ""}
                                    ref={ref}
                                  />
                                  {!fieldError.ssn &&
                                  errors.ssn &&
                                  touched.ssn ? (
                                    <span className="text-danger errors-fields">
                                      {errors.ssn}
                                    </span>
                                  ) : null}
                                  {!errors.ssn && fieldError.ssn && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.ssn}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="input-field-box">
                                  <label>
                                    Address 1{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Address 1"
                                    onChange={handleChange}
                                    name="address1"
                                    value={values.address1 || ""}
                                  />
                                  {errors.address1 && touched.address1 ? (
                                    <span className="text-danger errors-fields">
                                      {errors.address1}
                                    </span>
                                  ) : null}
                                  {fieldError.address1 && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.address1}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="input-field-box">
                                  <label>Address 2</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Address 2"
                                    onChange={handleChange}
                                    name="address2"
                                    value={values.address2 || ""}
                                  />
                                  {errors.address2 && touched.address2 ? (
                                    <span className="text-danger errors-fields">
                                      {errors.address2}
                                    </span>
                                  ) : null}
                                  {fieldError.address2 && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.address2}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <div
                                    className={`form-floating ${
                                      errors.state_id && touched.state_id
                                        ? "is-danger"
                                        : ""
                                    }`}
                                  >
                                    <Select
                                      classNamePrefix="react-select"
                                      className={`select_1 dropdown ${
                                        errors.state_id && touched.state_id
                                          ? "error-select-search"
                                          : ""
                                      }`}
                                      placeholder={"Select State"}
                                      options={userState}
                                      isSearchable={true}
                                      name="state_id"
                                      onChange={(selectedOption) => {
                                        setFieldValue("city_id", "");

                                        if (selectedOption) {
                                          setFieldValue(
                                            "state_id",
                                            selectedOption.value
                                          );
                                          changeUserCity(selectedOption.value);
                                        } else {
                                          setFieldValue("state_id", "");
                                        }
                                      }}
                                    />
                                    <label className="select-label">
                                      State
                                      <span className="text-danger"> *</span>
                                    </label>
                                    {errors.state_id && touched.state_id ? (
                                      <p className="help text-danger errors-fields">
                                        {errors.state_id}
                                      </p>
                                    ) : null}
                                    {fieldError.state && (
                                      <p className="help text-danger errors-fields">
                                        {fieldError.state}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  {userCityLoader ? (
                                    <h5 className="card-title placeholder-glow">
                                      <span
                                        className="placeholder col-12"
                                        style={{
                                          height: "60px",
                                          marginTop: "0px",
                                        }}
                                      ></span>
                                    </h5>
                                  ) : (
                                    <div
                                      className={`form-floating ${
                                        errors.city_id && touched.city_id
                                          ? "text-danger"
                                          : ""
                                      }`}
                                    >
                                      <Select
                                        className={
                                          errors.city_id && touched.city_id
                                            ? "error-select-search"
                                            : ""
                                        }
                                        placeholder={"Select City"}
                                        options={userCity}
                                        isSearchable={true}
                                        name="city_id"
                                        onChange={(selectedOption) => {
                                          if (selectedOption) {
                                            setFieldValue(
                                              "city_id",
                                              selectedOption.value
                                            );
                                          } else {
                                            setFieldValue("city_id", "");
                                          }
                                        }}
                                      />
                                      <label className="select-label">
                                        City{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      {errors.city_id && touched.city_id ? (
                                        <p className="help text-danger errors-fields">
                                          {errors.city_id}
                                        </p>
                                      ) : null}
                                      {fieldError.city && (
                                        <p className="help text-danger errors-fields">
                                          {fieldError.city}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    Zip Code{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Zip Code"
                                    onChange={handleChange}
                                    name="zipcode"
                                    value={values.zipcode || ""}
                                  />
                                  {errors.zipcode && touched.zipcode ? (
                                    <span className="text-danger errors-fields">
                                      {errors.zipcode}
                                    </span>
                                  ) : null}
                                  {fieldError.zip && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.zip}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="input-field-box">
                                  <label>
                                    Phone Number{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="phone"
                                    className="form-control"
                                    placeholder="Phone Number"
                                    onChange={handleChange}
                                    name="phone"
                                    value={values.phone || ""}
                                    // ref={inputRef}
                                  />
                                  {errors.phone && touched.phone ? (
                                    <span className="text-danger errors-fields">
                                      {errors.phone}
                                    </span>
                                  ) : null}
                                  {fieldError.phone && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.phone}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="prfil-set">
                        <div className="form-box">
                          <div className="row">
                            <div className="col-lg-12">
                              <h4 className="prf-hed">
                                DIGITAL SIGNATURE AND AGREEMENT
                              </h4>{" "}
                              <ul className="circle-list"></ul>
                              <br />
                            </div>
                          </div>

                          <div className="form-filds">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="input-field-box">
                                  <label>
                                    Electronic Signature{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Electronic Signature"
                                    onChange={handleChange}
                                    name="signature"
                                    value={values.signature || ""}
                                  />
                                  {errors.signature && touched.signature ? (
                                    <span className="text-danger errors-fields">
                                      {errors.signature}
                                    </span>
                                  ) : null}
                                  {fieldError.signature && (
                                    <p className="help text-danger errors-fields">
                                      {fieldError.signature}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="chek-term">
                                  <input
                                    id="radio-1"
                                    className="radio-custom"
                                    name="iAgree"
                                    onChange={handleChange}
                                    type="checkbox"
                                  />
                                  <label
                                    htmlFor="radio-1"
                                    className={`radio-custom-label terms-policy ${
                                      errors.iAgree && touched.iAgree
                                        ? "text-danger errors-fields"
                                        : ""
                                    }`}
                                  >
                                    &nbsp; I hereby confirm that the information
                                    provided above is correct and I authorize{" "}
                                    {process.env.REACT_APP_API_SITE_TITLE} to
                                    use my information.
                                  </label>

                                  {errors.iAgree && touched.iAgree ? (
                                    <p className="help text-danger errors-fields">
                                      {errors.iAgree}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-12">
                                <div className="chek-term">
                                  <input
                                    id="radio-4"
                                    className="radio-custom"
                                    name="termsAndConditions"
                                    onChange={handleChange}
                                    type="checkbox"
                                  />
                                  <label
                                    htmlFor="radio-4"
                                    className={`radio-custom-label terms-condition ${
                                      errors.termsAndConditions &&
                                      touched.termsAndConditions
                                        ? "text-danger errors-fields"
                                        : ""
                                    }`}
                                  >
                                    &nbsp;By registering for this promotion, I
                                    understand that promotion headquarters will
                                    send me periodic emails related to my
                                    status, participation, and general program
                                    announcements. Please see our{" "}
                                    <Link to="/term-conditions" target="_blank">
                                      terms and conditions
                                    </Link>{" "}
                                    and our
                                    <Link
                                      to="/privacy-statement"
                                      target="_blank"
                                    >
                                      {" "}
                                      privacy policy.
                                    </Link>
                                  </label>

                                  {errors.termsAndConditions &&
                                  touched.termsAndConditions ? (
                                    <p className="help text-danger errors-fields">
                                      {errors.termsAndConditions}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-12">
                                <ReCAPTCHA
                                  sitekey={
                                    process.env.REACT_APP_API_GOOGLESITEKEY
                                  }
                                  name="captcha"
                                  onChange={onChange}
                                />
                                {errorsCapMsg !== "" ? (
                                  <p className="help text-danger errors-fields">
                                    {errorsCapMsg}
                                  </p>
                                ) : null}
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="reset"
                                  className="round-red-btn w-100 mt-3 reset-reg"
                                  value="RESET FORM"
                                  onClick={() => window.location.reload(false)}
                                />
                              </div>

                              <div className="col-lg-6">
                                <button
                                  type="submit"
                                  className="round-red-btn w-100 mt-3 "
                                  value="REGISTER"
                                  onClick={handleButtonClick}
                                  // disabled={disable}
                                >
                                  <span
                                    className="xspinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  REGISTER
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>

      <footer className="login-footer-main register">
        Copyright &copy; {new Date().getFullYear()}{" "}
        <span className="login-fotr-title">
          {process.env.REACT_APP_API_SITE_TITLE}
        </span>
        . All rights reserved.
      </footer>
      <ToTop />
      {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#6c44c7"
          className="bar-loader"
        />
      )}

      {termsModel ? (
        <>
          <div className="modal fade show d-block" id="exampleModal">
            <div className="modal-dialog  modal-xl modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    TERMS AND CONDITIONS
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      hideMod();
                    }}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="p-policy">
                    <b>Agreement between user and Elite Marketing </b>
                    <p>
                      Welcome to Elite Marketing. The purplethanksyou.com
                      website (the "Site") is comprised of various web pages
                      operated by Elite Marketing and is offered to you
                      conditioned on your acceptance without modification of the
                      terms, conditions, and notices contained herein (the
                      "Terms"). Your use of purplethanksyou.com constitutes your
                      agreement to all such Terms. Please read these terms
                      carefully, and keep a copy of them for your reference.{" "}
                    </p>
                    <p>
                      <a href="http://www.purplethanksyou.com/" target="_blank">
                        www.purplethanksyou.com
                      </a>{" "}
                      is a spiff submission site.{" "}
                    </p>
                    <b>Privacy</b>
                    <p>
                      Your use of purplethanksyou.com is subject to Elite
                      Marketing's Privacy Policy. Please review our Privacy
                      Policy, which also governs the Site and informs users of
                      our data collection practices.
                    </p>
                    <b>Electronic Communications</b>
                    <p>
                      Visiting purplethanksyou.com or sending emails to Elite
                      Marketing constitutes electronic communications. You
                      consent to receive electronic communications and you agree
                      that all agreements, notices, disclosures and other
                      communications that we provide to you electronically, via
                      email and on the Site, satisfy any legal requirement that
                      such communications be in writing.{" "}
                    </p>
                    <b>Your Account</b>
                    <p>
                      If you use this site, you are responsible for maintaining
                      the confidentiality of your account and password and for
                      restricting access to your computer, and you agree to
                      accept responsibility for all activities that occur under
                      your account or password. You may not assign or otherwise
                      transfer your account to any other person or entity. You
                      acknowledge that Ashley Sleep or Elite Marketing are not
                      responsible for third party access to your account that
                      results from theft or misappropriation of your account.
                      Ashley Sleep or Elite Marketing and its associates reserve
                      the right to refuse or cancel service, terminate accounts,
                      or remove or edit content in our sole discretion.
                    </p>

                    <p>
                      Elite Marketing does not knowingly collect, either online
                      or offline, personal information from persons under the
                      age of thirteen. If you are under 18, you may use
                      purplethanksyou.com only with permission of a parent or
                      guardian.
                    </p>
                    <b>No unlawful or prohibited use/Intellectual Property</b>
                    <p>
                      You are granted a non-exclusive, non-transferable,
                      revocable license to access and use{" "}
                      <a href="http://www.purplethanksyou.com/" target="_blank">
                        www.purplethanksyou.com
                      </a>{" "}
                      strictly in accordance with these terms of use. As a
                      condition of your use of the Site, you warrant to Elite
                      Marketing that you will not use the Site for any purpose
                      that is unlawful or prohibited by these Terms. You may not
                      use the Site in any manner which could damage, disable,
                      overburden, or impair the Site or interfere with any other
                      party's use and enjoyment of the Site. You may not obtain
                      or attempt to obtain any materials or information through
                      any means not intentionally made available or provided for
                      through the Site.{" "}
                    </p>
                    <p>
                      All content included as part of the Service, such as text,
                      graphics, logos, images, as well as the compilation
                      thereof, and any software used on the Site, is the
                      property of Elite Marketing or its suppliers and protected
                      by copyright and other laws that protect intellectual
                      property and proprietary rights. You agree to observe and
                      abide by all copyright and other proprietary notices,
                      legends or other restrictions contained in any such
                      content and will not make any changes thereto. You will
                      not modify, publish, transmit, reverse engineer,
                      participate in the transfer or sale, create derivative
                      works, or in any way exploit any of the content, in whole
                      or in part, found on the Site. www.purplethanksyou.com
                      content is not for resale. Your use of the Site does not
                      entitle you to make any unauthorized use of any protected
                      content, and in particular you will not delete or alter
                      any proprietary rights or attribution notices in any
                      content. You will use protected content solely for your
                      personal use, and will make no other use of the content
                      without the express written permission of Elite Marketing
                      and the copyright owner. You agree that you do not acquire
                      any ownership rights in any protected content. We do not
                      grant you any licenses, express or implied, to the
                      intellectual property of Elite Marketing or our licensors
                      except as expressly authorized by these Terms.
                    </p>
                    <b>International Users</b>
                    <p>
                      The Service is controlled, operated and administered by
                      Elite Marketing from our offices within the USA. If you
                      access the Service from a location outside the USA, you
                      are responsible for compliance with all local laws. You
                      agree that you will not use the Elite Marketing content
                      accessed through{" "}
                      <a href="http://www.purplethanksyou.com/" target="_blank">
                        www.purplethanksyou.com
                      </a>{" "}
                      in any country or in any manner prohibited by any
                      applicable laws, restrictions or regulations.{" "}
                    </p>
                    <b>Indemnification</b>
                    <p>
                      You agree to indemnify, defend and hold harmless Elite
                      Marketing, its officers, directors, employees, agents and
                      third parties, for any losses, costs, liabilities and
                      expenses (including reasonable attorney's fees) relating
                      to or arising out of your use of or inability to use the
                      Site or services, any user postings made by you, your
                      violation of any terms of this Agreement or your violation
                      of any rights of a third party, or your violation of any
                      applicable laws, rules or regulations. Elite Marketing
                      reserves the right, at its own cost, to assume the
                      exclusive defense and control of any matter otherwise
                      subject to indemnification by you, in which event you will
                      fully cooperate with Elite Marketing in asserting any
                      available defenses.
                    </p>
                    <p>
                      Arbitration In the event the parties are not able to
                      resolve any dispute between them arising out of or
                      concerning these Terms and Conditions, or any provisions
                      hereof, whether in contract, tort, or otherwise at law or
                      in equity for damages or any other relief, then such
                      dispute shall be resolved only by final and binding
                      arbitration pursuant to the Federal Arbitration Act,
                      conducted by a single neutral arbitrator and administered
                      by the American Arbitration Association, or a similar
                      arbitration service selected by the parties, in a location
                      mutually agreed upon by the parties. The arbitrators award
                      shall be final, and judgment may be entered upon it in any
                      court having jurisdiction. In the event that any legal or
                      equitable action, proceeding or arbitration arises out of
                      or concerns these Terms and Conditions, the prevailing
                      party shall be entitled to recover its costs and
                      reasonable attorney's fees. The parties agree to arbitrate
                      all disputes and claims in regards to these Terms and
                      Conditions or any disputes arising as a result of these
                      Terms and Conditions, whether directly or indirectly,
                      including Tort claims that are a result of these Terms and
                      Conditions. The parties agree that the Federal Arbitration
                      Act governs the interpretation and enforcement of this
                      provision. The entire dispute, including the scope and
                      enforceability of this arbitration provision shall be
                      determined by the Arbitrator. This arbitration provision
                      shall survive the termination of these Terms and
                      Conditions.
                    </p>
                    <p>
                      Class Action Waiver Any arbitration under these Terms and
                      Conditions will take place on an individual basis; class
                      arbitrations and class/representative/collective actions
                      are not permitted. THE PARTIES AGREE THAT A PARTY MAY
                      BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL
                      CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                      PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE
                      PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY
                      GENERAL ACTION AGAINST THE OTHER. Further, unless both you
                      and Employer agree otherwise, the arbitrator may not
                      consolidate more than one person's claims, and may not
                      otherwise preside over any form of a representative or
                      class proceeding.
                    </p>
                    <b>Liability Disclaimer</b>
                    <p>
                      THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED
                      IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES
                      OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO
                      THE INFORMATION HEREIN. ELITE MARKETING AND/OR ITS
                      SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE
                      AT ANY TIME.
                    </p>
                    <p>
                      ELITE MARKETING AND/OR ITS SUPPLIERS MAKE NO
                      REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
                      AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION,
                      SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
                      CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM
                      EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION,
                      SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE
                      PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY
                      KIND. ELITE MARKETING AND/OR ITS SUPPLIERS HEREBY DISCLAIM
                      ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
                      INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                      GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS
                      OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                      TITLE AND NONINFRINGEMENT.
                    </p>
                    <p>
                      {" "}
                      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                      EVENT SHALLELITE MARKETING AND/OR ITS SUPPLIERS BE LIABLE
                      FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                      CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING,
                      WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR
                      PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE
                      USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR
                      INABILITY TO USE THE SITE OR RELATED SERVICES, THE
                      PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
                      INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                      GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING
                      OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT,
                      TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
                      ELITE MARKETING OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED
                      OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
                      STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                      LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
                      DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU
                      ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY
                      OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS
                      TO DISCONTINUE USING THE SITE.
                    </p>
                    <b> Termination/Access Restriction</b>
                    <p>
                      Elite Marketing and Ashley Sleep reserve the right, in its
                      sole discretion, to terminate your access to the Site and
                      the related services or any portion thereof at any time,
                      without notice. To the maximum extent permitted by law,
                      this agreement is governed by applicable state laws and
                      you hereby consent to the exclusive jurisdiction in all
                      disputes arising out of or relating to the use of the
                      Site. Use of the Site is unauthorized in any jurisdiction
                      that does not give effect to all provisions of these
                      Terms, including, without limitation, this section.
                    </p>
                    <p>
                      You agree that no joint venture, partnership, employment,
                      or agency relationship exists between you and Elite
                      Marketing as a result of this agreement or use of the
                      Site. Elite Marketing's performance of this agreement is
                      subject to existing laws and legal process, and nothing
                      contained in this agreement is in derogation of Elite
                      Marketing's right to comply with governmental, court and
                      law enforcement requests or requirements relating to your
                      use of the Site or information provided to or gathered by
                      Elite Marketing with respect to such use. If any part of
                      this agreement is determined to be invalid or
                      unenforceable pursuant to applicable law including, but
                      not limited to, the warranty disclaimers and liability
                      limitations set forth above, then the invalid or
                      unenforceable provision will be deemed superseded by a
                      valid, enforceable provision that most closely matches the
                      intent of the original provision and the remainder of the
                      agreement shall continue in effect.
                    </p>
                    <p>
                      Unless otherwise specified herein, this agreement
                      constitutes the entire agreement between the user and
                      Elite Marketing with respect to the Site and it supersedes
                      all prior or contemporaneous communications and proposals,
                      whether electronic, oral or written, between the user and
                      Elite Marketing with respect to the Site. A printed
                      version of this agreement and of any notice given in
                      electronic form shall be admissible in judicial or
                      administrative proceedings based upon or relating to this
                      agreement to the same extent and subject to the same
                      conditions as other business documents and records
                      originally generated and maintained in printed form. It is
                      the express wish to the parties that this agreement and
                      all related documents be written in English.
                    </p>
                    <b> Changes to Terms</b>
                    <p>
                      {" "}
                      Elite Marketing reserves the right, in its sole
                      discretion, to change the Terms under which spiffs are
                      offered. The most current version of the Terms will
                      supersede all previous versions. Elite Marketing
                      encourages you to periodically review the Terms to stay
                      informed of our updates.
                    </p>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <button
                          type="button"
                          onClick={() => {
                            submission();
                          }}
                          className="round-red-btn w-100 mt-3 reset-reg border-0"
                        >
                          I agree with terms and conditions
                        </button>
                      </div>
                      <div className="col-lg-6">
                        <button
                          type="button"
                          onClick={() => {
                            hideMod();
                          }}
                          className="round-red-btn w-100 mt-3  border-0"
                          data-bs-dismiss="modal"
                        >
                          I disagree with terms and conditions
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      ) : null}
    </div>
  );
}
