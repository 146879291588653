import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";

import ReactLoading from "react-loading";

import { useFormik } from "formik";

import { addSizeProduct } from "../../../schema";
import Select from "react-select";
import AdminListService from "../../../services/admin-list.service";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import Skeleton from "react-loading-skeleton";

export default function AddProductSize() {
  const [loading, setLoading] = useState(false);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, adminId } = state;
  const navigate = useNavigate();
  const [tableData, settableData] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [sizeListLoading, setSizeListLoading] = useState(false);

  const productsLists = async () => {
    try {
      const responce = await AdminListService.listAllProducts();

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          value: value.id,
          label: value.name,
        });
      });

      settableData([...results]);
    } catch (err) {
      settableData([]);
    }
  };
  useEffect(() => {
    if (adminAccessToken) {
      productsLists();
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const getSizesById = async (id) => {
    try {
      setSizeListLoading(true);
      const responce = await AdminListService.getSizeByProductId(id);

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          value: value,
          label: value,
        });
      });

      setSizeList([...results]);
      setSizeListLoading(false);
    } catch (err) {
      setSizeList([]);
      setSizeListLoading(false);
    }
  };
  const addNewProduct = async (values, action) => {
    setLoading(true);

    try {
      const response = await AdminListService.addSizeForProduct(
        values,
        adminId
      );

      toast.success("Added Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      action.resetForm();
      navigate("/admin/view-product");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.error(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.status === 422) {
        if (err?.response?.data?.prod_id) {
          setFieldError("prdName", err?.response?.data?.prod_id[0]);
        }
        if (err?.response?.data?.price) {
          setFieldError("prdPrice", err?.response?.data?.price[0]);
        }
        if (err?.response?.data?.size) {
          setFieldError("prdSize", err?.response?.data?.size[0]);
        }
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      prdName: "",
      prdPrice: "",
      prdSize: "",
    },
    validationSchema: addSizeProduct,
    onSubmit: (values, action) => {
      addNewProduct(values, action);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="heading-sectiom admin">Add Product Size</div>
          <div className="row">
            <div className="mt-4 col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Product Size Details</h4>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-12 mb-3">
                      <div
                        className="alert alert-primary d-flex align-items-center font-17 "
                        role="alert"
                      >
                        <svg
                          width="1em"
                          height="1em"
                          xmlns="http://www.w3.org/2000/svg"
                          className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                          viewBox="0 0 16 16"
                          role="img"
                          aria-label="Warning:"
                          style={{ marginTop: "-2px" }}
                        >
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        <div>
                          In this page only those products will appear whose any
                          Size is pending
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Select Product <span className="text-danger">*</span>
                        </label>

                        {tableData.length ? (
                          <Select
                            classNamePrefix="react-select"
                            className={`select_1 dropdown`}
                            placeholder="Select Product"
                            options={tableData}
                            isSearchable={true}
                            isClearable={false}
                            // menuIsOpen={true}
                            tabIndex="6"
                            name="prdName"
                            onChange={(selectedOption) => {
                              setFieldValue("prdSize", "");

                              if (selectedOption) {
                                setFieldValue("prdName", selectedOption.value);
                                getSizesById(selectedOption.value);
                              } else {
                                setFieldValue("prdName", "");
                                setSizeList([]);
                              }
                            }}
                          />
                        ) : (
                          <Skeleton width="100%" count={1} height="55px" />
                        )}

                        {errors.prdName && touched.prdName ? (
                          <span className="text-danger">{errors.prdName}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Select Product Size{" "}
                          <span className="text-danger">*</span>
                        </label>
                        {!sizeListLoading ? (
                          <Select
                            classNamePrefix="react-select"
                            className={`select_1 dropdown`}
                            placeholder="Select Product Size"
                            options={sizeList}
                            isSearchable={true}
                            isClearable={true}
                            tabIndex="6"
                            name="prdSize"
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                setFieldValue("prdSize", selectedOption.value);
                              } else {
                                setFieldValue("prdSize", "");
                              }
                            }}
                          />
                        ) : (
                          <Skeleton width="100%" count={1} height="55px" />
                        )}
                        {errors.prdSize && touched.prdSize ? (
                          <span className="text-danger">{errors.prdSize}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Product Price <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          min="1"
                          className="form-control"
                          placeholder="Product Price"
                          onChange={handleChange}
                          name="prdPrice"
                          value={values.prdPrice || ""}
                        />
                        {errors.prdPrice && touched.prdPrice ? (
                          <span className="text-danger">{errors.prdPrice}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#6c44c7"
          className="bar-loader"
        />
      )}
    </div>
  );
}
