import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";

import ReactLoading from "react-loading";

import { useFormik } from "formik";

import { addProduct } from "../../../schema";

import AdminListService from "../../../services/admin-list.service";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";

export default function AddProducts() {
  const [loading, setLoading] = useState(false);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, adminId } = state;
  const navigate = useNavigate();

  useEffect(() => {
    if (adminAccessToken) {
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const addNewProduct = async (values, action) => {
    setLoading(true);
    try {
      const response = await AdminListService.newProductAddition(
        values,
        adminId
      );

      toast.success("Added Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      action.resetForm();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.error(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.status === 422) {
        if (err?.response?.data?.name) {
          setFieldError("prdName", err?.response?.data?.name[0]);
        }
        if (err?.response?.data?.number) {
          setFieldError("prdNo", err?.response?.data?.number[0]);
        }
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, setFieldError, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        prdName: "",
        prdNo: "",
      },
      validationSchema: addProduct,
      onSubmit: (values, action) => {
        addNewProduct(values, action);
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="heading-sectiom admin">Add Product</div>
          <div className="row">
            <div className="mt-4 col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Product Details</h4>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-12 mb-3">
                      <div
                        className="alert alert-primary d-flex align-items-center font-17 "
                        role="alert"
                      >
                        <svg
                          width="1em"
                          height="1em"
                          xmlns="http://www.w3.org/2000/svg"
                          className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                          viewBox="0 0 16 16"
                          role="img"
                          aria-label="Warning:"
                          style={{ marginTop: "-2px" }}
                        >
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        <div>
                          Please make sure dont enter the product CODE with the
                          name. Product name should be like : Destin Shores Ltd.
                          CODE: M927
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Product Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Name"
                          onChange={handleChange}
                          name="prdName"
                          value={values.prdName || ""}
                        />
                        {errors.prdName && touched.prdName ? (
                          <span className="text-danger">{errors.prdName}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Product Number <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Number"
                          onChange={handleChange}
                          name="prdNo"
                          value={values.prdNo || ""}
                        />
                        {errors.prdNo && touched.prdNo ? (
                          <span className="text-danger">{errors.prdNo}</span>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="input-field-box mb-0">
                        <label>
                          Phone <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone"
                          onChange={handleChange}
                          
                          name="phone"
                          value={values.phone || ""}
                          ref={inputRef}
                        />
                        {errors.phone && touched.phone ? (
                          <span className="text-danger">{errors.phone}</span>
                        ) : null}
                      </div>
                    </div> */}

                    <div className="col-12">
                      <button className="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#6c44c7"
          className="bar-loader"
        />
      )}
    </div>
  );
}
