import React, { useEffect } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";
import Skeleton from "react-loading-skeleton";
import Pagination from "react-js-pagination";
import useTable from "../../../hooks/useTable";
import useSortableData from "../../../hooks/useSortableData";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import moment from "moment";
import formatThousands from "format-thousands";
import Swal from "sweetalert2";
import $ from "jquery";
import { toast } from "react-toastify";

export default function ListProducts() {
  const [tableLoader, settableLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("10");
  const [resultData, setResultData] = useState([]);
  const [sortCol, setSortCol] = useState("true");
  const [sortDir, setSortDir] = useState("true");
  const [sortColErr, setSortColErr] = useState("");
  const [sortDirErr, setSortDirErr] = useState("");
  const [dirDisabled, setDirDisabled] = useState(true);
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("0");
  const date = new Date();

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, adminId } = state;
  const navigate = useNavigate();

  const productsLists = async (page, limit, values, sortCol, sortDir) => {
    if (sortCol && sortCol != "true") {
      setDirDisabled(false);
      setSortCol(sortCol);
    }
    if (sortDir) {
      setSortDir(sortDir);
    }

    if (sortCol != "true" && sortDir == "true") {
      setSortDirErr("Please select this");
      return;
    } else if (sortCol == "true" && sortDir != "true") {
      setSortColErr("Please select this");
      return;
    } else if (sortCol != "true" && sortDir != "true") {
      setSortColErr("");
      setSortDirErr("");
    }

    settableLoader(true);
    try {
      const responce = await AdminListService.viewProducts(
        page,
        limit,
        values,
        sortCol,
        sortDir
      );

      const res = responce?.data?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          size_id: value.size_id,
          product_name: value.product_name,
          size: value.size,
          size_price: value.size_price,
        });
      });

      settableData([...results]);
      setTotalPages(responce?.data?.total_pages);
      setTotalResults(responce?.data?.records);
      // setResultData(res);
      setCurrentPage(responce?.data?.current_page);
      setLimit(responce?.data?.per_page);

      settableLoader(false);
    } catch (err) {
      settableLoader(false);
      settableData([]);
      setTotalPages("0");
      setTotalResults("0");
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      productsLists("1", limit, []);
    } else {
      navigate("/admin/login", { replace: true });
    }
  }, []);

  const delProduct = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletSingleProduct(
            delId,
            adminId
          );

          productsLists(currentPage, limit, values);

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,

    initialValues: {
      fieldtype: "",
      searchval: "",
    },
    validationSchema: quizSearch,
    onSubmit: (values) => {
      productsLists("1", limit, values);
    },
  });

  const Table = ({ data }) => {
    return (
      <>
        {tableLoader ? (
          <div className="card-body pt-0">
            <Skeleton count={11} height="50px" />
          </div>
        ) : (
          <div className="table-responsive">
            <table className="text-nowrap table">
              <thead className="table-light">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Product</th>
                  <th scope="col">Size</th>
                  <th scope="col">Price</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.length ? (
                  data.map((el, index) => (
                    <tr key={index}>
                      <td className="align-middle">
                        {currentPage === 1
                          ? index + 1
                          : (currentPage - 1) * limit + index + 1}{" "}
                      </td>
                      <td className="align-middle">
                        {el.product_name ? el.product_name : "N/A"}
                      </td>
                      <td className="align-middle">
                        {el.size ? el.size : "N/A"}
                      </td>
                      <td className="align-middle">
                        {el.size_price ? el.size_price : "N/A"}
                      </td>

                      <td className="align-middle ">
                        <div className="d-flex align-items-center">
                          <Link
                            to={`/admin/edit-product-price/${el.size_id}`}
                            className="btn btn-success btn-sm btn-icon-text me-3"
                          >
                            Edit <i className="bi bi-pencil-square"></i>
                          </Link>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm btn-icon-text"
                            onClick={() => {
                              delProduct(el.size_id);
                            }}
                          >
                            Delete <i className="bi bi-x-circle"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center text-capitalize">
                      No record found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <br />
            {totalResults > limit && totalPages > 1 ? (
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={parseInt(limit)}
                totalItemsCount={totalResults}
                onChange={(e) => {
                  productsLists(e, limit, values, sortCol, sortDir);
                }}
                pageRangeDisplayed={8}
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First Page"
                lastPageText="Last Page"
              />
            ) : (
              ""
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="heading-sectiom admin">View Products</div>
          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <div className="col-md-8">
                      <h4 className="mb-0">
                        Total Products:{" "}
                        <span className="text-primary-color fw-bold">
                          {formatThousands(totalResults, {
                            separator: ",",
                          })}
                        </span>
                      </h4>
                    </div>
                    <div className="col-md-4 text-end"></div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-lg-0">
                        <label>Show Column</label>
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="">Select Column</option>
                          <option value="title">Product</option>
                          <option value="size">Size</option>
                          <option value="price">Price</option>
                        </select>
                        {errors.fieldtype && touched.fieldtype ? (
                          <span className="text-danger">
                            {errors.fieldtype}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="input-field-box mb-lg-0">
                        <label className="spiff-search">Search</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            name="searchval"
                            value={values.searchval || ""}
                            required
                          />

                          <button className="btn btn-primary " type="submit">
                            Search
                          </button>
                          <button
                            className="btn btn-primary"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                        {errors.searchval && touched.searchval ? (
                          <span className="text-danger">
                            {errors.searchval}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-field-box mb-0">
                        <label>Show Entries</label>
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) =>
                            productsLists("1", e.target.value, values)
                          }
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="input-field-box mb-0">
                        <label>Sort By Column</label>
                        <select
                          className="form-select"
                          value={sortCol}
                          name="sort_column"
                          onChange={(e) =>
                            productsLists(
                              "1",
                              limit,
                              values,
                              e.target.value,
                              sortDir
                            )
                          }
                        >
                          <option value>Select Column</option>
                          <option value="product_name">Product</option>
                          <option value="size">Size</option>
                          <option value="size_price">Price</option>
                        </select>
                        {sortColErr && (
                          <span className="text-danger">{sortColErr}</span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="input-field-box mb-0">
                        <label>Sort By Direction</label>
                        <select
                          disabled={dirDisabled}
                          className="form-select"
                          value={sortDir}
                          onChange={(e) =>
                            productsLists(
                              "1",
                              limit,
                              values,
                              sortCol,
                              e.target.value
                            )
                          }
                        >
                          <option value>Select Direction</option>
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </select>
                        {sortDirErr && (
                          <span className="text-danger">{sortDirErr}</span>
                        )}
                      </div>
                    </div>
                  </form>
                </div>

                {tableLoader ? (
                  <div className="card-body">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <Table data={tableData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
