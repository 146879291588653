import React, { useEffect } from "react";
import Footer from "../../user/Dashboard/includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import "../../user/style.css";
import GreetingsUserMessage from "./includes/GreetingsUserMessage";
import UserService from "../../../services/user.service";
import thumbUp from "../Dashboard/includes/images/thumb-up-icon.png";
import pendingIcon from "../Dashboard/includes/images/clock-icon.png";

export default function UserDashboard() {
  const [loading, setLoading] = useState(false);
  const [tableLoader, settableLoader] = useState(false);
  const [counters, setCounters] = useState([]);
  const [tableData, settableData] = useState([]);

  const state = useSelector((state) => state.stateVals);
  const { accessToken, id, name } = state;
  const navigate = useNavigate();

  const list = async () => {
    settableLoader(true);
    try {
      const response = await UserService.latestOrder(id);

      const res = response.data.response;
      const results = [];
      res.map((value) => {
        return results.push({
          sale_id: value.sale_id,
          sale_name: value.sale_name,
          points: value.points,
          cron: value.cron,
          regDate: value.created_at,
          reportDate: value.cron_date,
          id: value.id,
          archive: value.archive,
        });
      });

      settableData([...results]);

      settableLoader(false);
    } catch (err) {
      settableLoader(false);
      settableData([]);
    }
  };

  useEffect(() => {
    if (accessToken) {
      list();
    } else {
      navigate("/", { replace: true });
    }
  }, []);
  const Table = ({ data }) => {
    return (
      <div className="table-responsive">
        <table className="text-nowrap mb-0 table">
          <thead className="table-light">
            <tr>
              <th scope="col">No</th>
              <th scope="col">Sales ID</th>
              <th scope="col">Sales Person Name</th>
              <th scope="col">Reward Amount</th>
              <th scope="col">Date</th>
              <th scope="col">Report Date</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.length ? (
              data?.map((el, index) => (
                <tr key={index}>
                  <td className="align-middle">{index + 1} </td>
                  <td className="align-middle">{el.sale_id} </td>
                  <td className="align-middle">{el.sale_name} </td>
                  <td className="align-middle">$ {el.points} </td>
                  <td className="align-middle">
                    {moment(el.date).format("MM-DD-YYYY")}
                  </td>
                  <td className="align-middle">
                    {el.reportDate !== null
                      ? moment(el.reportDate).format("MM-DD-YYYY")
                      : "N/A"}
                  </td>

                  {el.cron === "yes" && (
                    <td className="yes" style={{ color: "#00b775" }}>
                      {/* <i
                        className="fa fa-thumbs-up"
                        style={{ color: "#28a745" }}
                      >
                        {" "}
                        Approved
                      </i> */}
                      <img className="thumb-up-icon" src={thumbUp} alt="icon" />
                      {" Approved"}
                      <br />
                    </td>
                  )}
                  {el.cron === "no" && (
                    <td className="no" style={{ color: "#fd6c00" }}>
                      {/* <i className="fa fa-clock-o" style={{ color: "#e0876c" }}>
                        {" "}
                        Pending
                      </i> */}
                      <img
                        className="thumb-up-icon"
                        src={pendingIcon}
                        alt="icon"
                      />
                      {" Pending"}
                      <br />
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="wrapper">
      <div className="container">
        <main className="page-content customer-contnent">
          <div className="user-main-design">
            <ul className="user-circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <div className="user-content-glass">
              <GreetingsUserMessage admin={name} />
              <div className="row justify-content-md-center">
                <div className="  col-lg-6">
                  <h2 className="p-title text-center">
                    <b>Purple Thanks You</b>
                  </h2>
                </div>

                <div className="alert alert-info shadow-soft" role="alert">
                  <span className="alert-inner--text">
                    For questions regarding your reward(s) Email:{" "}
                    <a
                      href="mailto:info@purplethanksyou.com"
                      className="alert-link"
                    >
                      info@purplethanksyou.com
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-5 row">
              <div className="col-md-12 col-12">
                <div className="card dashboard-cards">
                  <div className="bg-white  py-3 card-header">
                    <div className="col-md-8">
                      <h4 className="mb-4">Recent Spiffs </h4>
                    </div>
                    {/* <div className="col-12">
                      <div
                        className="alert alert-primary d-flex align-items-center font-17 "
                        role="alert"
                      >
                        <svg
                          width="1em"
                          height="1em"
                          xmlns="http://www.w3.org/2000/svg"
                          className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                          viewBox="0 0 16 16"
                          role="img"
                          aria-label="Warning:"
                          style={{ marginTop: "-2px" }}
                        >
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        <div>
                          {" "}
                          <strong>Info!</strong> Here is a summary of your
                          account.
                        </div>
                      </div>
                    </div> */}

                    <div className="col-12">
                      <div
                        className="alert-content-glass alert alert-primary d-flex align-items-center font-17"
                        role="alert"
                      >
                        <svg
                          width="1em"
                          height="1em"
                          xmlns="http://www.w3.org/2000/svg"
                          className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                          viewBox="0 0 16 16"
                          role="img"
                          fill="#052c65"
                          aria-label="Warning:"
                          style={{ marginTop: "-2px" }}
                        >
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        <div>
                          <strong>Info!</strong> Here is a summary of your
                          account.
                        </div>
                      </div>
                    </div>
                  </div>

                  {tableLoader ? (
                    <div className="card-body">
                      <Skeleton count={11} height="50px" />
                    </div>
                  ) : (
                    <Table data={tableData} />
                  )}

                  <div className="bg-white text-center card-footer">
                    <Link className="link-primary spiffs" to="/view-spiffs">
                      View All Spiffs
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}
