import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";

import ReactLoading from "react-loading";

import { useFormik } from "formik";

import { addSizeProduct } from "../../../schema";
import Select from "react-select";
import AdminListService from "../../../services/admin-list.service";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import Skeleton from "react-loading-skeleton";

export default function EditProductSize() {
  const [formSubmiting, setFormSubmiting] = useState(false);
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, adminId } = state;
  const navigate = useNavigate();

  const { id } = useParams();
  const productPrice = async () => {
    try {
      setFormSubmiting(true);
      const responce = await AdminListService.productPriceBySizeId(id);

      const res = responce?.data?.response;
      setFieldValue("prdPrice", res.price);
      setFieldValue("prdName", res.product_name);
      setFieldValue("prdSize", res.size);
      setFormSubmiting(false);
    } catch (err) {
      setFormSubmiting(false);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      productPrice();
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const addNewProduct = async (values, action) => {
    setFormSubmiting(true);

    try {
      const response = await AdminListService.updatePriceForProductSize(
        values,
        adminId,
        id
      );

      toast.success("Updated Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      action.resetForm();
      navigate("/admin/view-product");
      setFormSubmiting(false);
    } catch (err) {
      setFormSubmiting(false);

      console.log(err);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.error(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.status === 422) {
        if (err?.response?.data?.prod_id) {
          setFieldError("prdName", err?.response?.data?.prod_id[0]);
        }
        if (err?.response?.data?.price) {
          setFieldError("prdPrice", err?.response?.data?.price[0]);
        }
        if (err?.response?.data?.size) {
          setFieldError("prdSize", err?.response?.data?.size[0]);
        }
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      prdName: "",
      prdPrice: "",
      prdSize: "",
    },
    validationSchema: addSizeProduct,
    onSubmit: (values, action) => {
      addNewProduct(values, action);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="heading-sectiom admin">Update Product Price</div>
          <div className="row">
            <div className="mt-4 col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Product Details</h4>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Select Product <span className="text-danger">*</span>
                        </label>{" "}
                        <input
                          type="test"
                          className="form-control"
                          placeholder="Product Price"
                          readOnly={true}
                          name="prdName"
                          value={values.prdName || ""}
                        />
                        {errors.prdName && touched.prdName ? (
                          <span className="text-danger">{errors.prdName}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Select Product Size{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="test"
                          className="form-control"
                          placeholder="Product Price"
                          readOnly={true}
                          name="prdSize"
                          value={values.prdSize || ""}
                        />
                        {errors.prdSize && touched.prdSize ? (
                          <span className="text-danger">{errors.prdSize}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field-box">
                        <label>
                          Product Price <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          min="1"
                          className="form-control"
                          placeholder="Product Price"
                          onChange={handleChange}
                          name="prdPrice"
                          value={values.prdPrice || ""}
                        />
                        {errors.prdPrice && touched.prdPrice ? (
                          <span className="text-danger">{errors.prdPrice}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary" type="submit">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {formSubmiting && (
        <ReactLoading
          type="spinningBubbles"
          color="#6c44c7"
          className="bar-loader"
        />
      )}
    </div>
  );
}
