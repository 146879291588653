import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import { bindActionCreators } from "redux";
import $ from "jquery";
import Logo from "../../../Images/logo2.png";
import { actionCreaters } from "../../../Redux";
import "./Menu.css";
import UserIcon from "../../../Images/user_9650323.png";
import editIcon from "../login/edit.png";
import logoutIcon from "../login/logout.png";
export default function Header() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, adminName } = state;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const [Dark, setDark] = useState(localStorage.getItem("theme"));

  const [isMenuActive, setMenuActive] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuActive(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggle = () => {
    setMenuActive(!isMenuActive);
  };

  useEffect(() => {
    if (adminAccessToken) {
      if (Dark === "DarkTheme") {
        $("html").attr("class", "dark-theme");
      } else {
        $("html").attr("class", "light-theme");
      }
    }
  }, [Dark, adminAccessToken]);

  const setTheme = async () => {
    if (Dark !== "DarkTheme") {
      localStorage.setItem("theme", "DarkTheme");
      setDark("DarkTheme");
    } else {
      localStorage.setItem("theme", "LightTheme");
      setDark("LightTheme");
    }
  };

  const logOut = async () => {
    localStorage.removeItem("theme");
    await userActions.logOut("adminLogout");
    navigate("/admin/login", { replace: true });
  };

  const menuBtn = () => {
    $(".site-main-design").toggleClass("full-show");
    $(".site-footer-main").toggleClass("full-show");
  };

  // const toggle = () => {
  //   let menu = document.querySelector(".menu");
  //   menu.classList.toggle("active");
  // };

  return (
    <>
      <header className="top-header customer-header">
        <nav className="navbar navbar-expand gap-3">
          <button
            id="menu-open-close"
            onClick={menuBtn}
            data-bs-target="#sidebar"
            data-bs-toggle="collapse"
            className="border rounded-1 p-1 text-decoration-none"
          >
            <i className="bi bi-list bi-lg "></i>
          </button>
          <Link to="/admin/dashboard" className="custom-logo">
            <img src={Logo} alt="logo icon" />
          </Link>

          <div className="theme-changer">
            <input
              type="checkbox"
              className="checkbox"
              id="checkbox"
              checked={Dark === "DarkTheme" ? true : false}
              readOnly={true}
            />
            <label
              htmlFor="checkbox"
              className="checkbox-label"
              onClick={setTheme}
            >
              <i className="bi bi-moon-fill"></i>
              <i className="bi bi-brightness-high-fill"></i>
              <span className="ball"></span>
            </label>
          </div>

          <div className="top-navbar-right ms-auto">
            <ul className="navbar-nav align-items-center">
              <li
                className="nav-item dropdown dropdown-user-setting"
                ref={dropdownRef}
              >
                <nav>
                  <div className="profile" onClick={toggle}>
                    <div className="user">
                      <h3>Welcome</h3>
                      <span className="text-capitalize">
                        {adminName ? adminName : ""}
                      </span>
                    </div>
                    <div className="img-box">
                      <img
                        src={UserIcon}
                        style={{ padding: "0px" }}
                        alt="user-image"
                      />
                    </div>
                  </div>
                  <div className={`menu ${isMenuActive ? "active" : ""}`}>
                    <ul className="menu-list">
                      <li>
                        <Link
                          className="change-pass-btn"
                          to="/admin/profile"
                          onClick={toggle}
                        >
                          {/* <img
                            src={editIcon}
                            style={{ padding: "0px", width: "30px" }}
                            alt="edit-icon"
                          /> */}
                          <i className="bi bi-pencil-square"></i>
                          Edit Profile
                        </Link>
                      </li>
                      <li>
                        <Link onClick={logOut} className="logout-btn">
                          {/* <img
                            src={logoutIcon}
                            style={{ padding: "0px", width: "30px" }}
                            alt="logout-icon"
                          /> */}
                          <i className="bi bi-box-arrow-left"></i>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}
