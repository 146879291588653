import React, { useLayoutEffect } from "react";
import { Link, useMatch } from "react-router-dom";
import Logo from "../../../Images/logo.webp";
import LoginFooter from "./UserLogin/LoginFooter";

export default function ThankYou() {
  const match = useMatch("/thankyou", { caseSensitive: false });

  useLayoutEffect(() => {
    if (match?.pattern?.path === match?.pathname) {
    } else {
      window.location.href = "/thankyou";
      //   navigate("/thankyou", { replace: true });
    }
  }, []);
  return (
    <div className="wrapper">
      <div className="container">
        <main className="page-content customer-contnent">
          <div className="user-main-design thanks-page">
            <header className="main-header">
              <nav className="navbar navbar-expand-lg navbar-light flex-column">
                <Link className="navbar-brand" to="/">
                  <img src={Logo} className="login-logo register" alt="" />
                </Link>
                <div className="alred-memb">
                  Go Back To
                  <Link className="round-red-btn" to="/">
                    LOGIN
                  </Link>
                </div>
              </nav>
            </header>
            <ul className="user-circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <div className="user-content-glass">
              <div className="text-center">
                <div className="heading-user-sectiom greetings-message user">
                  <strong className="user-name">
                    THANK YOU FOR REGISTRATION
                  </strong>
                </div>
              </div>

              <div className="row justify-content-md-center">
                <div className="alert alert-info shadow-soft" role="alert">
                  <span className="alert-inner--text">
                    We have received your information, our team will review your
                    information and process the account. <br />
                    You will receive an activation email once we have processed
                    your account.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <LoginFooter />
    </div>
  );
}
